import { render, staticRenderFns } from "./CookHistory.vue?vue&type=template&id=732e9cd4&scoped=true&"
import script from "./CookHistory.vue?vue&type=script&lang=js&"
export * from "./CookHistory.vue?vue&type=script&lang=js&"
import style0 from "./CookHistory.vue?vue&type=style&index=0&id=732e9cd4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.9.8_vue-template-compiler@2.6.14/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "732e9cd4",
  null
  
)

export default component.exports