<template>
  <section class="content">
    <div class="row">
      <div class="col-12">
        <div class="card mt-2">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h2 class="card-title mb-1 ml-2">Recipe Build History</h2>
            <div class="ml-auto">
              <button class="btn btn-secondary" @click="goBack">Back</button>
            </div>
          </div>
          <div class="card-body">
            <div class="row align-items-start mb-3">
              <div class="col-lg-3">
                <div class="img-wrapper">
                  <img :src="worklist.recipe.is_catalog_image ? worklist.recipe.catalog.image : worklist.recipe.image" alt="">
                </div>
              </div>
              <div class="col-lg-9 pl-3">
                  <h5>{{ worklist.recipe.name }}</h5>
                  <h5>Admin Name : {{ worklist.recipe.admin.name }}</h5>
                  <h5>Employee Name : {{ worklist.employee.name }}</h5>
                  <h5>Updated At : {{ worklist.recipe.updated_at }}</h5>
                </div>
            </div>
            <div class="row mb-3">
              <div class="col-6">
                <table id="table" class="table table-bordered table-striped table-hover">
                  <thead class="bg-primary">
                    <tr>
                      <th rowspan="1" colspan="3" class="text-light">Ingredients</th>
                    </tr>
                    <tr>
                      <th class="text-light">Ingredients Required</th>
                      <th class="text-light">Amount</th>
                      <th class="text-light">Actual Amount</th>
                    </tr>
                  </thead>
                  <tbody v-if="ingredients && ingredients.length > 0">
                    <tr v-for="(ingredient, index) in ingredients" :key="index">
                      <td>{{ ingredient.catalog.name }}</td>
                      <td>{{ (ingredient.quantity*worklist.batch_size).toFixed(2) }} {{ getUnitOfMeasure(ingredient.catalog, ingredient.variation_data_id) }}</td>
                      <td>{{ ingredient.work_list_ingredient_details ? (ingredient.work_list_ingredient_details.quantity).toFixed(2) : null }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-6">
                <table id="table" class="table table-bordered table-striped table-hover">
                  <thead class="bg-primary">
                    <tr>
                      <th class="text-light">Steps & Instruction</th>
                    </tr>
                  </thead>
                  <tbody v-if="worklist.recipe.steps.length > 0">
                    <tr v-for="(step, index) in worklist.recipe.steps" :key="index">
                      <td>{{ step.instruction }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-6">
                <table id="table" class="table table-bordered table-striped table-hover">
                  <thead class="bg-primary">
                    <tr>
                      <th rowspan="1" colspan="2" class="text-light">Other Ingredients</th>
                    </tr>
                    <tr>
                      <th class="text-light">Ingredients Required</th>
                      <th class="text-light">Amount</th>
                    </tr>
                  </thead>
                  <tbody v-if="worklist.additional_ingredients.length > 0">
                    <tr v-for="(additionalIngredient, index) in worklist.additional_ingredients" :key="index">
                      <td>{{ additionalIngredient.catalog.name }}</td>
                      <td>{{ (additionalIngredient.quantity*worklist.batch_size).toFixed(2) }} {{ getUnitOfMeasure(additionalIngredient.catalog, additionalIngredient.variation_data_id) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-6">
                <table id="table" class="table table-bordered table-striped table-hover">
                  <thead class="bg-primary">
                    <tr>
                      <th class="text-light">Steps</th>
                    </tr>
                  </thead>
                  <tbody v-if="worklist.instructions">
                    <tr>
                      <td>{{ worklist.instructions }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'CookHistoryShow',
  components: {},
  props: {
    worklist : {
      type : Object
    },
    ingredients : {
      type : Array
    }
  },
  created() {
  },
  data() {
    return {
      viewCookHistoryDetails: ''
    }
  },
  methods: {
    getUnitOfMeasure(catalog, variationDataId) {
      let variationsData = catalog.variations_data.find((uom) => uom.id === variationDataId)
      return variationsData.item_variation_data['name'];
    },
    goBack() {
      this.$emit('view-cook-history-index');
    }
  },
  computed: {

  },
}
</script>
<style scoped>
/* td > a > i {
  cursor: pointer;
}
.img-wrapper {
  min-height: 100px !important;
} */

table th {
  font-size: 16px;
  font-weight: 500;
}

</style>
